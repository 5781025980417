import React from 'react'
import { Link } from 'gatsby'
import './Footer.scss'
import { FaLinkedin, FaTwitterSquare, FaFacebook } from 'react-icons/fa'

const Footer = () => (
  <footer>
    <div className="footer-wrap">
      <div className="footer-inner-wrap texture-bg-small">
        <div className="upper-footer-wrap">
          <div className="col-md-6 col-no-padding footer-left-col">
            <div className="footer-left-header">
              <h3>Who we are</h3>
              <p className="footer-left-subheader">
                Rackner builds cutting-edge solutions that apply DevSecOps and
                the power of AI in the datacenter, public/private clouds, and
                edge.
              </p>
            </div>
            <div className="footer-btn-contact-wrap">
              <Link style={{ textDecoration: 'none' }} to="/contact">
                <button className="btn-raised-light-blue">Contact Us</button>
              </Link>
              <span />
              <Link style={{ textDecoration: 'none' }} to="/quote">
                <button className="btn-raised-light-blue">Get a Quote</button>
              </Link>
            </div>
            <div className="footer-logos-row">
              &nbsp;&nbsp;
              <img src="/HUBZoneCertified.png" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img src="/AWSPartner.png" />
            </div>
            <div className="footer-logos-row">
              &nbsp;&nbsp;
              <img src="/ISO9001.png" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img src="/ISO27001.png" />
            </div>
          </div>
          <div className="col-md-3 footer-right-col">
            <div className="footer-right-col-header">
              <h4>Info</h4>
            </div>
            <div className="footer-right-col-sub">
              <a href="/services">
                <h5>Services</h5>
              </a>
              <a href="/kubernetes">
                <h5>Kubernetes</h5>
              </a>
              <a href="/insights">
                <h5>Insights</h5>
              </a>
              <a href="/boost/">
                <h5>Boost</h5>
              </a>
              <a href="/contractvehicles">
                <h5>Contract Vehicles</h5>
              </a>
              <a href="/about">
                <h5>About</h5>
              </a>
              <a href="https://boards.greenhouse.io/rackner">
                <h5>Careers</h5>
              </a>
            </div>
          </div>
          <div className="col-md-3 footer-right-col">
            <div className="footer-right-col-header">
              <h4>Locations</h4>
            </div>
            <div className="footer-right-col-sub">
              <span><b>Silver Spring Office</b></span>
              <br></br>
              <span>8630 Fenton St, Suite 330</span>
              <br />
              <span>Silver Spring, MD 20910</span>
              <br />
              <br />
              <span><b>College Park Office (Principal)</b></span>
              <br></br>
              <span>7338 Baltimore Avenue, Suite 102</span>
              <br />
              <span>College Park, Maryland 20740</span>
              <br />
              <br />
              <a
                className="footer-contact-number"
                href="mailto:contact@rackner.com"
              >
                <span>
                  <b>contact@rackner.com</b>
                </span>
              </a>
              <br></br>
              <br></br>
              <span>
                <b>UEI:</b> MCLCAYU7FB38
              </span>
            </div>
          </div>
        </div>
        <div className="lower-footer-wrap">
          <div className="col-xs-6 sub-footer-text-copyright">
            &#xA9; 2022 Rackner, Inc.
          </div>
          <div className="col-xs-6 sub-footer-social-wrapper">
            <a href="https://www.linkedin.com/company/rackner-solutions">
              <FaLinkedin />
            </a>
            <a href="https://twitter.com/racknerco">
              <FaTwitterSquare />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
